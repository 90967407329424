import Colors from "../../../constants/Colors"
import { useState } from "react";

const Button = (props)=>{

    const [isHovered, setIsHovered] = useState(false);

    const styles = {
        container : {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor : isHovered ? Colors.primaryColor : Colors.primaryBackground,
            color: isHovered ? Colors.primaryBackground : Colors.primaryColor,
            cursor: isHovered ? "pointer" : "arrow",
            height : "5vh",
            padding : "20px",
            transition: "background-color 0.5s, color 0.5s",
            borderRadius: "0.6rem",
        },
        text:{
            fontSize: "1rem",
        }
    }

    return (
        <a href={"#"+props.a} style={{textDecoration: 'none'}}>
            <div style={styles.container} onMouseEnter={()=>setIsHovered(true)} onMouseLeave={()=>setIsHovered(false)}>
                <div style={styles.text}>
                    {props.caption}
                </div>            
            </div>
        </a>
    )
}

export default Button;