import emailjs from 'emailjs-com';

emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);

export const sendEmail = async (data) => {
  let mailData = {
    from_name: data.name,
    to_name: data.name,
    message: data.message,
    telefono: data.phone,
    email: data.email,
  };

  return emailjs.send(
    process.env.REACT_APP_EMAILJS_SERVICE_ID,
    process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    mailData
  );
};