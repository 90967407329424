import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import back1 from '../../assets/back1.png';
import './Inicio.css'

const TextCarousel = ({ textArray, backgroundImage }) => {

  const backgroundImageUrl = back1;

  return (
    <section id="inicio" style={{
      backgroundImage: `url(${backgroundImageUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center'
    }}>
      <Carousel >
          <Carousel.Item  className='car-item' >
           <div style={{ textAlign: 'left', marginLeft: '10%', marginTop: '8%' }}>
              <div className="textContainer">
                <div className="line">¡Abrimos tu <span className='highlighted-text'>LLC</span></div>
                <div className="line">y Cuenta Bancaria menos de <span className='highlighted-text'>10 días</span>!</div>
              </div>
            </div>          
          </Carousel.Item>

          <Carousel.Item  className='car-item'>
           <div style={{ textAlign: 'left', marginLeft: '10%', marginTop: '8%' }}>
              <div className="textContainer">
                <div className="line">¡Factura y cobra en <span className='highlighted-text' >dólares</span>, </div>
                <div className="line">expande tu negocio </div>
                <div className="line">en Todo el <span className='highlighted-text'>Mundo!</span></div>                
              </div>
            </div>          
          </Carousel.Item>

          <Carousel.Item className='car-item'>
           <div style={{ textAlign: 'left', marginLeft: '10%', marginTop: '8%' }}>
              <div className="textContainer">
                <div className="line">Tu Camino Seguro Hacia la </div>
                <div className="line"><span className='highlighted-text'>Internacionalización:</span></div>
                <div className="line">Protege tus Activos con una  <span className='highlighted-text'>LLC</span> en  <span className='highlighted-text'>U.S.A.</span></div>
              </div>
            </div>          
          </Carousel.Item>
      </Carousel>
          <div className="custom-contact-button">
            <a className='btn car-cus-btn' href='#pricing'>Empieza ahora</a>
          </div>
    </section>
  );
};

export default TextCarousel;
