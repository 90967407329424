import './Header.css'
import logoSrc from '../../assets/logo-02.png';
import Button from './Button/Button';
import { useState } from 'react';


const Header = ()=>{


    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const styles = {

        menu: {
            display: isMenuOpen ? 'flex' : 'none',
            flexDirection:  'column'
            // ... otros estilos que puedas necesitar para el menú ...
        },
    };

    
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <header id='inicio' style={styles.header} className='header'>
            <img src={logoSrc} alt="Logo" className="logo" />
            <div style={{zIndex: 99999}} className={`buttons ${isMenuOpen ? 'active' : ''}`}>
                <Button caption={"Servicios"} a={"features"}/>
                <Button caption={"FAQs"} a={"faq"}/>
                {/*<Button caption={"Pricing"} a={"pricing"}/>*/}
                <Button caption={"Contacto"} a={"contacto"}/>
            </div>
            <div className="hamburger-menu"  onClick={toggleMenu}>
                <span className="hamburger-button">☰</span>
            </div>
        </header>
    );
};

export default Header;