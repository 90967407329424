import React, { useState, useEffect } from 'react';
import { Chrono } from 'react-chrono';
import "./Timeline.css";
const timelineItems = [
    {
      title: "Conoce el camino para abrir tu LLC",
      cardTitle: "Conoce el camino para abrir tu LLC",
      cardSubtitle:"",
      cardDetailedText: "Análisis de tu negocio para determinar el Estado que mejor se adapte a tus necesidades."
    },
    {
      title: "Revision del nombre",
      cardTitle: "Revision del nombre",
      cardSubtitle:"",
      cardDetailedText: "Corroboramos que el nombre de tu LLC esté disponible."
    },
    {
      title: " Artículos de Organización.",
      cardTitle: " Artículos de Organización",
      cardSubtitle:"",
      cardDetailedText: "Presentamos la documentación necesaria para aperturar tu empresa. (24 horas)"
    },
    {
      title: "Obtenemos el EIN",
      cardTitle: "Obtenemos el EIN (número de identificación fiscal)",
      cardSubtitle:"",
      cardDetailedText: "Necesario para abrir tu cuenta bancaria! (5 días hábiles)"
    },
    {
      title: " Abrimos la cuenta bancaria empresarial",
      cardTitle: " Abrimos la cuenta bancaria empresarial",
      cardSubtitle:"",
      cardDetailedText: "100% online, sin requisitos, depósito inicial ni costos de mantenimiento! (Inmediato)"
    },
    {
      title: "LLC lista para operar!",
      cardTitle: "LLC lista para operar!",
      cardSubtitle:"",
      cardDetailedText: "¡Tu LLC ya está lista para despegar! Inicia tu éxito empresarial con confianza y seguridad."
    },
  ];
  
  function Timeline() {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth < 768);
      };
  
      // Agrega un event listener cuando el componente se monta
      window.addEventListener('resize', handleResize);
  
      // Limpia el event listener cuando el componente se desmonta
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
  
    // Si es mobile, retorna null o algún otro elemento que prefieras mostrar
    if (isMobile) {
      return null; // O puedes retornar un mensaje o cualquier otro componente alternativo
    }
  
    // Si no es mobile, muestra el timeline
    return (
      <section id="proceso">
        <div className="timeline-container">
          <Chrono items={timelineItems} mode="HORIZONTAL" />
        </div>
      </section>
    );
  }

export default Timeline;
