import React, { useState } from 'react';
import './FAQ.css'; // Asegúrate de que la ruta al archivo CSS sea correcta

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleIndex = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    { question: "¿Qué es una LLC?", answer: "Una LLC (Limited Liability Company) es una estructura empresarial que combina la simplicidad de una sociedad de personas con la protección de responsabilidad limitada similar a una corporación. Ofrece a los propietarios (llamados miembros) protección de sus activos personales y flexibilidad en la gestión de su negocio." },
    { question: "¿Cuáles son los beneficios de formar una LLC?", answer: " Formar una LLC puede proteger tus bienes personales en caso de problemas legales o financieros en tu negocio. También brinda flexibilidad en la gestión y opciones fiscales ventajosas, además de acceso al mercado de Estados Unidos." },
    { question: "¿Puedo abrir una LLC si soy extranjero?", answer: "Sí, los no residentes pueden formar una LLC en los Estados Unidos. No se requiere ciudadanía ni residencia para ser miembro de una LLC." },
    { question: "¿Cómo elijo el estado para formar mi LLC?", answer: "La elección del estado depende de varios factores, como la ubicación de tu negocio y las leyes fiscales locales. Podemos ayudarte a determinar el estado que mejor se adapte a tus necesidades." },
    { question: "¿Cuáles son los costos asociados con la formación de una LLC?", answer: "Los costos varían según el estado y los servicios que necesites. Esto incluye tarifas de presentación estatales, costos del agente registrado y posiblemente tarifas de consultoría. Podemos proporcionarte un presupuesto personalizado." },
    { question: "¿Cuánto tiempo lleva formar una LLC?", answer: "El tiempo de formación varía según el estado y la complejidad de tu solicitud. Por lo general, puede llevar de unas pocas semanas a varios meses. Ayudamos a agilizar el proceso tanto como sea posible" },
    { question: "¿Puedo cambiar la estructura de mi LLC después de su formación?", answer: " Sí, en muchos casos puedes realizar cambios en la estructura o gestión de tu LLC después de su formación. Estos cambios pueden requerir presentación de documentos y cumplimiento de requisitos estatales." },
    { question: "¿Qué servicios adicionales ofrecen además de la formación de la LLC?", answer: "Además de la formación de la LLC, ofrecemos servicios como asesoría fiscal, mantenimiento de cuentas contables mensuales y otros. No dudes en contactarnos!" }
  ];
  const halfwayPoint = Math.ceil(faqs.length / 2);
  const firstColumnFaqs = faqs.slice(0, halfwayPoint);
  const secondColumnFaqs = faqs.slice(halfwayPoint);

  return (
    <section id="faq" className="faq-container">
      <h2 className="faq-title">FAQ</h2>
      <div className="faq-columns">
        <div className="faq-column">
          {firstColumnFaqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleIndex(index)}>
                {faq.question}
                <span className="faq-toggle">{activeIndex === index ? '-' : '+'}</span>
              </div>
              <div className={`faq-answer ${activeIndex === index ? 'active' : ''}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
        <div className="faq-column">
          {secondColumnFaqs.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleIndex(index + halfwayPoint)}>
                {faq.question}
                <span className="faq-toggle">{activeIndex === index + halfwayPoint ? '-' : '+'}</span>
              </div>
              <div className={`faq-answer ${activeIndex === index + halfwayPoint ? 'active' : ''}`}>
                {faq.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FaqSection;