import Swal from 'sweetalert2'
import './statics/AlertService.css';


export const sendAlert=(message, isError)=>{
      return Swal.fire({
        title: "LLC-YA!",
        text: message,
        icon: (!isError)?"success":"error",
        customClass: {
          container: 'custom-swal-container',
          title: 'custom-swal-title',
          content: 'custom-swal-content',
          confirmButton: 'custom-swal-confirm-button',
        },
        background: '#0C2A42', // Color de fondo azul
        buttonsStyling: false,
        confirmButtonText: 'Aceptar'
      });

}