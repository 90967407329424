import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import './WhatsAppButton.css'

function WhatsAppButton() {
    const phoneNumber = '+5491127708023';
    const message = encodeURIComponent('Hola, tengo una consulta sobre la gestión de una LLC.');
  
    const handleWhatsAppClick = () => {
        const url = `https://wa.me/${phoneNumber}?text=${message}`;
    
        window.open(url, '_blank');
      };

  return (
    <button className="whatsapp-button" onClick={handleWhatsAppClick}>
      <FontAwesomeIcon icon={faWhatsapp} />
    </button>
  );
}

export default WhatsAppButton;
