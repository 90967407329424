// import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import './Features.css';

const serviciosText = [
  {
      title: "Por qué elegirnos:",
      subtitle1: "Somos expertos en la creación y gestión de LLCs en Estados Unidos",
      subtitle2: "Nuestro compromiso es con tu crecimiento!",
    cards: [
      {
        title: "Conocimiento Profesional",
        icon:  <Icon.Book size={50}/>, 
        description: "Más de 10 años de experiencia en el rubro a tu disposición."
      },
      {
        title: "Asesoría personalizada",
        icon: <Icon.PersonCheck size={50}/>, 
        description: "No solo te ayudamos a abrir tu empresa, también te asesoramos para que elijas la mejor opción según tu tipo de actividad."
      },
      {
        title: "Seguimiento continuo",
        icon: <Icon.Eye size={50}/>, 
        description: "Te acompañamos y resolvemos tus dudas durante todo el proceso."
      }
    ]
  }
  // ... puedes añadir más objetos de secciones aquí
];

const FlipCard = ({ title, icon, description, color }) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="inner-front-card-container">
            <div className="icon-card">{icon}</div>
            <h3>{title}</h3>
          </div>
        </div>
        <div className={`flip-card-back ${(color % 2) === 0 ? 'red-back' : 'blue-back'}`}>
          <div className="inner-back-card-container">
            <h4>{description}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};


const FeaturesSection = () => {
  return (
    <Container id="features" className="conteiners features-container">
      {serviciosText.map((section, index) => (
        <div key={index}>
          <h2 className="text-center mb-4 title">{section.title}</h2>
          <h4 className="text-center mb-6 subtitle">{section.subtitle1}</h4>
          <h4 className="text-center mb-6 subtitle1">{section.subtitle2}</h4>
          <Row className="justify-content-center cardContainer">
            {section.cards.map((card, index) => (
              <Col md={3} key={index}>
                <FlipCard title={card.title} icon={card.icon} description={card.description} color={index} />
              </Col>
            ))}
          </Row>
        </div>
      ))}
    </Container>
  );
};

export default FeaturesSection;
