import React, {useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ContactUs.css'
import logo from  '../../assets/logo-02.png';
import { sendEmail } from '../../services/mailService';
import { sendAlert } from '../../services/AlertService';
// import axios from 'axios';

const ContactForm = () => {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await sendEmail(formData)
        .then ((res)=>{
          sendAlert("El mensaje se ha enviado con exito", false)
           .then((res)=>{
             console.log(res)
             window.location.href = '#inicio';
             setFormData({
               name: '',
               email: '',
               phone: '',
               message: ''
             });
           })
        })
        .catch((err)=>{
          console.log(err)
          sendAlert("El mensaje no se ha enviado. Intente más tarde.", true)
        })
      // const formData = {
      //   nombre: event.target.name.value,
      //   email: event.target.email.value,
      //   telefono: event.target.phone.value,
      //   comentario: event.target.message.value,
      // };

      // const config = {
      //   headers: {
      //     'Authorization': `bearer ${process.env.REACT_APP_API_KEY}`,
      //     'secret-key': process.env.REACT_APP_SECRET_KEY,
      //   }
      // };

      // const endpoint = process.env.REACT_APP_API_ENDPOINT;

      // console.log("endpoint: ", endpoint)
      // console.log("headers: ", config.headers)

      // await axios.post(endpoint, formData, config)
      // .then((res) => {
      //   sendAlert("El mensaje se ha enviado con éxito", false)
      //     .then((res) => {
      //       console.log(res);
      //       window.location.href = '#inicio';
      //       setFormData({
      //         name: '',
      //         email: '',
      //         phone: '',
      //         message: ''
      //       });
      //     });
      // })
      // .catch((err) => {
      //   console.log(err);
      //   sendAlert("El mensaje no se ha enviado. Intente más tarde.", true);
      // });

      // await sendEmail(formData);
    } catch (error) {
      console.error('Hubo un error al enviar los datos:', error);
    }
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.id]: event.target.value });
  };
  return (
    <section id="contacto" className="container-contact mt-5">
      <div className="logo-container">
        <div className="logo-container-inner">
          <img className='line-logo' src={logo} alt="logo"/>
          <h2 className='cu-line-1'>ABRE TU EMPRESA USA</h2>
          <h2 className='cu-line-2'>¡Abre Tu Cuenta Ahora Mismo!</h2>
          <h2 className='cu-line-3'>Completa el formulario o comunícate por WhatsApp y nos pondremos en contacto contigo a la brevedad para avanzar con el proceso</h2>
        </div>
      </div>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Nombre*</label>
          <input type="text" className="form-control" id="name" placeholder="Nombre" required onChange={handleChange} value={formData.name}/>
        </div>
        <div className="form-group">
          <label htmlFor="email">Mail*</label>
          <input type="email" className="form-control" id="email" placeholder="ejemplo@email.com" required onChange={handleChange} value={formData.email}/>
        </div>
        <div className="form-group">
          <label htmlFor="phone">Número de Teléfono*</label>
          <input type="tel" className="form-control" id="phone" placeholder="(+54) 911 9999-9999" required onChange={handleChange} value={formData.phone}/>
        </div>
        <div className="form-group">
          <label htmlFor="message">Comentario</label>
          <textarea className="form-control" id="message" rows="3" placeholder="Tu mensaje aquí" onChange={handleChange} value={formData.message}></textarea>
        </div>
        <button type="submit" className="btn btn-primary custom-button" >Enviar</button>
      </form>
    </section>
  );
}

export default ContactForm;